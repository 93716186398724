
.Ghost {
  width: 201px;
  height: 192px;
  margin: 273px 0 0 0;
  position: absolute;
  top: 0px;
  right: 0px;
}

.GhostCheckIn {
  width: 100px;
  height: 100px;
  margin: 58px 0 0 0;
  position: absolute;
  top: 0px;
  right: 0px;
}

.GhostOther {
  width: 0px;
  height: 0px;
  top: 0px;
  right: 0px;
  position: absolute;
  visibility: hidden;
}

.GhostConfirm {
  width: 201px;
  height: 192px;
  margin: 99px 0 0 0;
  transform: scaleX(-1);;
  position: absolute;
  top: 0px;
  left: 0px;

}

/* 当视口高度小于等于600px时应用的样式 */
@media (max-height: 700px) {
  .Ghost {

    width: 201px;
    height: 192px;
    margin: 213px 0 0 0
  }
}



