.WishesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.TipsInput {
  display: flex;
  background-color: transparent;
  border: 0px solid #ccc;
  font-family: 'qssx';
  font-size: 13px;
  resize: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.UserText{
  white-space: nowrap;
}
.UserEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}