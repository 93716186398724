.MessageContainer {



  /* 使用遮罩来创建透明度渐变效果 */
  -webkit-mask-image: linear-gradient(to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 10%,
          rgba(0, 0, 0, 1) 90%,
          rgba(0, 0, 0, 0) 100%);


}