.ListBg {
  background-image: url(./images/bg_checkin.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center
}

.OkBtn {
  position: absolute;
  bottom: 70px;

}

/* 当视口高度小于等于600px时应用的样式 */
@media (max-height: 700px) {

  .List {
    display: flex;
    flex-direction: column;
    align-items: center
  }

  .OkBtn {
    position: absolute;
    bottom: 10px;

  }
}

.CheckinDialogBg {
  background-image: url(./images/bg_checkin_dialog.png);
  background-size: 318px 330px;

}



.ItemBorder {
  width: 318px;
  /* border-left: 1px solid #424242;
  border-top: 1px solid #424242;
  border-right: 1px solid #424242; */
  border-bottom: 1px solid #424242;


}

.ItemText {
  font-family: 'qssx';
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 隐藏溢出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
  font-size: 13px;
  color: #7E7E7E;

}




.DateDialogBorder {
  border-left: 4px solid #424242;
  border-top: 4px solid #424242;
  border-right: 4px solid #424242;


}