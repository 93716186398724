.DmContainer {



    /* 使用遮罩来创建透明度渐变效果 */
    -webkit-mask-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 40%,
            rgba(0, 0, 0, 1) 60%,
            rgba(221, 40, 40, 0) 100%);


}

.DmScroll {
    overflow: hidden;
    animation: scroll 30s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(146px);
    }

    100% {
        transform: translateY(-100%);
    }
}



/* .StoneShadow {
    color: #fff;
    text-shadow:
        0.5px -1px 0px rgba(115, 98, 67, 1);
}
.StoneShadow::placeholder {
    color: #fff;
    text-shadow:
        0.5px -1px 0px rgba(115, 98, 67, 1);
} */

.StoneShadow-1 {
    position: relative;
    display: inline-block;
    font-size: 60px;
    font-weight: bold;
    color: white;
    background-color: #333;
    padding: 10px;
    text-shadow: 0.5px -1px 0.5px rgba(115, 98, 67, 1);
    /* Optional outer shadow for better effect */
}

.StoneShadow-1::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    text-shadow:
        -0.5px 1px 0.5px rgba(115, 98, 67, 1),
        0.5px -1px 0.5px rgba(115, 98, 67, 1);
    z-index: -1;
}