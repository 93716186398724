

.MusicContainer {
  display: grid;
  grid-template-columns:  1fr  1fr ;
}
.Music{
  transition: transform 0.5s ease;
}
.MusicRotating {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

