@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Rotating div */
.mask_bg {

  animation: rotate 2s linear infinite;
  /* Animation property */
}