.Btn {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  border-radius: 0px;
  border: 5px solid orange;
  border-image: url(./images/btn_bg.png) 5 5 5 5 fill stretch;
  font-size: 20px;
}
.RlDialogBg{

  /* 垂直居中 */
  border-radius: 0px;
  border: 35px solid orange;
  border-image: url(./images/dialog_bg.png) 35 35 35 35 fill stretch;
  font-size: 20px;
}



.CommonDialog {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  border-radius: 0px;
  border: 5px solid orange;
  border-image: url(./images/btn_bg.png) 5 5 5 5 fill stretch;
  font-size: 17px;
}

.no-border .ant-list-item {
  border-bottom: none;
  border-top: none;
}

.no-border-header .ant-list-header {
  border-bottom: none; /* 去掉 header 下的分割线 */
}
.Main {
  background-image: url('images/bg.png');
  background-size: cover;
  width: 100vw;
  height: 100%;
  font-family: 'ipix';
  color: #424242;
  -webkit-touch-callout: none; /* 禁用长按菜单 */
  user-select: none; /* 禁用文本选择 */
}
.Input {
  border-radius: 0px;
  padding: 0;
  border: 1px solid #424242;
  font-family: 'qssx';
  font-size: 17px;
  text-align: center;
  outline: none;
  box-shadow: inset 2px 2px 0 rgba(171, 171, 171, 0.6);
}

.Input::placeholder {
  color: #A8A8A8;
  /* 你想要的颜色 */
}

.marquee {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.EnterBtn {
  animation: EnterBtn 2s linear infinite;
}

@keyframes EnterBtn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.HiddenScrollBar{
  
}
.HiddenScrollBar::-webkit-scrollbar {
  width: 0; /* 隐藏滚动条 */
  height: 0; /* 隐藏滚动条 */
  display: none; /* 强制不显示滚动条 */
}