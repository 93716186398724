



.TextContent {
  margin: 0 0  210px 0
 }
 .BottomBtn{
   margin:0 0 80px 0
 }
/* 当视口高度小于等于600px时应用的样式 */
@media (max-height: 700px) {


   .TextContent {
    margin: 0 0  80px 0
   }
   .BottomBtn{
    margin:0 0 10px 0
  }
}

.Btn {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  border-radius: 0px;
  border: 5px solid orange;
  border-image: url(./images/btn_bg.png) 5 5 5 5 fill stretch;
  font-size: 20px;
}